<template>
  <div class="configurationEdit-content bg-white pt-4">
    <div class="containter">
      <b-form>
        <div class="row">
          <div class="col">
            <div class="col-12">
              <div class="row mb-3">
                <div class="col-4">
                  <label class="text-sm text-color-text">Account :</label>
                </div>
                <div class="col">
                  <strong class="text-sm text-secondary">{{
                    campaign.account_name
                  }}</strong>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <label class="text-sm text-color-text" for="input-group-name"
                    >Name</label
                  >
                </div>
                <div class="col">
                  <b-form-group id="input-group-name mb-0">
                    <b-form-input
                      id="input-name-edit"
                      class="text-sm text-secondary"
                      v-model="campaign.name"
                    ></b-form-input>
                  </b-form-group>
                  <div
                    v-if="!$v.campaign.name.required"
                    class="message-alert text-sm"
                  >
                    Name is required
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-4">
                  <label
                    class="text-sm text-color-text"
                    for="input-group-startDate-edit"
                    >Start Date</label
                  >
                </div>
                <div class="col">
                  <b-form-group id="input-group-startDate-edit">
                    <b-form-datepicker
                      id="input-startDate-edit mb-0"
                      v-model="campaign.dates.start"
                      class="text-sm text-secondary"
                    ></b-form-datepicker>
                  </b-form-group>
                  <div
                    v-if="pressButton && !$v.campaign.dates.start.required"
                    class="message-alert text-sm"
                  >
                    Start Date is required
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <label
                    class="text-sm text-color-text"
                    for="input-group-startDate-edit"
                    >End Date</label
                  >
                </div>
                <div class="col">
                  <b-form-group id="input-group-endDate-edit">
                    <b-form-datepicker
                      id="input-endDate-edit"
                      v-model="campaign.dates.end"
                      class="text-sm text-secondary"
                    ></b-form-datepicker>
                  </b-form-group>
                  <div
                    v-if="pressButton && !$v.campaign.dates.end.required"
                    class="text-sm message-alert"
                  >
                    End Date is required
                  </div>
                  <div v-if="isValidateDate" class="text-sm message-alert">
                    Invalid date
                  </div>
                  <cp-warning-message
                    message="You can change the campaign end date while the campaign still active."
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="text-sm text-secondary">Type :</label>
                </div>
                <div class="col">
                  <strong class="text-sm text-secondary">{{
                    campaign.type === "Audiencie" ? "Audience" : campaign.type
                  }}</strong>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>
          <div class="col">
            <goals-list-edit :campaign="campaign"></goals-list-edit>
          </div>
        </div>
        <b-button
          type="button"
          @click="saveCampaign()"
          variant="primary"
          id="button-submit-edit"
          class="mt-3"
        >
          <span class="text-sm text-white"> Save Changes </span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  CpWarningMessage,
  mapGetters,
  mapActions,
  UserObjMixin,
} from "@isc/styleguide";
import { required } from "vuelidate/lib/validators";
import GoalsListEdit from "../goals/GoalsListEdit";
import Leads from "../goals/Leads.vue";
import moment from "moment";

export default {
  name: "ConfigurationEdit",
  props: ["campaign"],
  components: {
    GoalsListEdit,
    Leads,
    CpWarningMessage,
  },
  mixins: [UserObjMixin],
  data() {
    return {
      pressButton: false,
      isValidateName: false,
      isValidateDate: false,
      //minDate: new Date(), :min="minDate"
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  watch: {
    "campaign.dates.start"() {
      this.isValidateDate = false;
    },
    "campaign.dates.end"() {
      this.isValidateDate = false;
    },
  },
  methods: {
    ...mapActions({
      updateCampaign: "campaign/campaign/UPDATE_CAMPAIGN",
    }),
    onSubmit() {
      if (this.campaign.goals) {
        this.pressButton = true;
        let numberGoals = Object.entries(this.campaign.goals).filter(
          (tupla) => {
            return tupla[1] != null;
          }
        ).length;
        if (!numberGoals) {
          return false;
        }
      }
      if (this.campaign.leads) {
        if (this.campaign.leads <= 0) {
          return false;
        }
        if (this.campaign.leads % 1 != 0) {
          return false;
        }
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      console.log(this.campaign);
      this.$router.push("/campaigns/all");
    },
    checkDate(start, end) {
      let mStart = moment(start);
      let mEnd = moment(end);
      return mStart.isBefore(mEnd);
    },
    async saveCampaign() {
      if (this.$v.$invalid) return;

      if (!this.checkDate(this.campaign.dates.start, this.campaign.dates.end)) {
        this.isValidateDate = true;
        return;
      }
      const result = await this.updateCampaign({
        idCampaign: this.campaign.key,
        campaign: this.campaign,
      });

      if (result.codigo == 200) {
        this.$emit("messageCreated", true);
        this.$router.push({ name: "campaigns-all" });
      } else {
        this.$bvToast.toast("error when creating campaign", {
          title: "Message",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  validations: {
    campaign: {
      account_name: {
        required,
      },
      name: {
        required,
      },
      dates: {
        start: {
          required,
        },
        end: {
          required,
        },
      },
    },
  },
  async mounted() {
    if (!this.userInfo) await this.setCurrentObjUser();
    this.campaign.timezoneId = this.userInfo.ID_ZONA_HORARIA;
  },
};
</script>

<style scoped>
#button-submit-edit {
  margin-left: 45%;
  margin-bottom: 30px;
}

.message-alert {
  color: red;
  font-size: 12px;
}
#input-group-endDate-edit {
  margin-bottom: 6px;
}
</style>
