<template>
  <div class="modal-contant">
    <div class="d-flex justify-content-center mb-4 text-center label-list">
      <span class="span-progress" :class="{ 'paint-span': nameDate }"
        >Name and Date</span
      ><span class="span-progress" :class="{ 'paint-span': budgetType }"
        >Budget and Type</span
      ><span class="span-progress" :class="{ 'paint-span': confirmation }"
        >Confirmation</span
      >
    </div>
    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <name-date
          v-if="nameDate"
          @next="nextBudget"
          @closeModal="closeModal"
          :campaign="campaign"
        ></name-date>
        <budget-type
          v-if="budgetType"
          @next="nextConfirmation"
          @prev="prevNameData"
          :campaign="campaign"
        ></budget-type>
        <confirmation
          v-if="confirmation"
          @prev="prevBudget"
          @closeModal="closeModal"
          @messageCreated="messageCreated"
          @submit="submit"
          :campaign="campaign"
          @active-loader="activeLoader"
          @inactive-loader="inactiveLoader"
        ></confirmation>
      </b-form>
    </div>
  </div>
</template>

<script>
import NameDate from "./NameData";
import BudgetType from "./BudgetType";
import Confirmation from "./Corfirmation";
import { mapActions, mapGetters } from "@isc/styleguide";
export default {
  name: "ModalCampaign",
  components: {
    NameDate,
    BudgetType,
    Confirmation,
  },
  data() {
    return {
      nameDate: true,
      budgetType: false,
      confirmation: false,
      submit: false,
      campaign: {
        name: null,
        type: "Audience",
        goals: [],
        leads: null,
        reached: {
          facebook: null,
          twiter: null,
          instagram: null,
          youtube: null,
        },
        account_name: null,
        account_id: null,
        dates: {
          start: null,
          end: null,
        },
        timezoneId: 0,
        table : "",
        isActive: true,
        currency: null,
        idcurrency: null,
        budget: null,
        posts: [],
        clientId: null
      },
    };
  },
  async mounted() {
    try {
      await this.getMetricsAudience();
      await this.getMetricsLead();
      await this.getListCurrency();
    } catch (error) {
      console.error("[Error] in mounted Modal Campaign", error);
    }
  },
  methods: {
    //first slide
    ...mapActions({
      getMetricsAudience: "campaign/campaign/GET_METRICS_AUDIENCE",
      getMetricsLead: "campaign/campaign/GET_METRICS_LEAD",
      getListCurrency: "campaign/campaign/GET_LIST_CURRENCY",
    }),
    nextBudget() {
      this.nameDate = false;
      this.budgetType = true;
      this.confirmation = false;
    },

    closeModal(closeModal) {
      this.$emit("closeModal", closeModal);
    },
    messageCreated(created) {
      this.$emit("messageCreated", created);
    },
    activeLoader(){
      this.$emit('active-loader');
    },
    inactiveLoader(){
      this.$emit('inactive-loader');
    },
    //second slide
    nextConfirmation() {
      this.nameDate = false;
      this.budgetType = false;
      this.confirmation = true;
    },

    prevNameData() {
      this.nameDate = true;
      this.budgetType = false;
      this.confirmation = false;
    },

    // third slide
    prevBudget() {
      this.nameDate = false;
      this.budgetType = true;
      this.confirmation = false;
    },

    onSubmit() {
      if (this.campaign.type == "Audience") {
        this.campaign.leads = null;
      }
      if (this.campaign.type == "Leads") {
        this.campaign.goals = null;
      }
      this.$emit("closeModal", true);
    },
  },
};
</script>

<style scoped>
.modal-dialog {
    width: 1080px !important;
}
.modal-lg {
    width: 1080px !important;
}
.span-progress {
  padding: 0.5rem 3rem;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  background-color: var(--mainbg);
}

.paint-span {
  background-color: var(--primary);
  color: white;
}
</style>
