<template>
  <div class="contenedoressx">
    <loader-custom v-if="visibilityLoader"></loader-custom>
    <bread-crumb />
    <div class="contenedores detail-content-box">
      <div class="d-flex align-items-center justify-content-between mt-3 mb-3">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-lg text-secondary"
              >{{ this.marcaRes }}
            </span>
            <i id="allViewTitle" class="fa fa-info-circle iconInfoCampView"></i>
            <CpTooltipWhiteCustom
              target="allViewTitle"
              title="allViewTitle"
              paddingContainer="15px 5px 10px 15px"
              message="The campaign analysis depends on the start and end date of the campaign."
            />
          </div>
          <div v-for="(idRedSocial, index) in redSocialCamp" :key="index">
            <i
              v-if="idRedSocial == 1"
              @click="abrirpestania(itemsq.dscUrlRedsocial)"
              class="mr-2 fab fa-facebook text-facebook"
            ></i>
            <i
              v-if="idRedSocial == 4"
              @click="abrirpestania(itemsq.dscUrlRedsocial)"
              class="mr-2 fab fa-youtube text-youtube"
            ></i>
            <i
              v-if="idRedSocial == 3"
              @click="abrirpestania(itemsq.dscUrlRedsocial)"
              class="mr-2 fab fa-instagram text-instagram"
            ></i>
            <i
              v-if="idRedSocial == 2"
              @click="abrirpestania(itemsq.dscUrlRedsocial)"
              class="mr-2 fab fa-twitter text-twitter"
            ></i>
            <i
              v-if="idRedSocial == 5"
              @click="abrirpestania(itemsq.dscUrlRedsocial)"
              class="mr-2 fab fa-linkedin text-linkedin"
            ></i>
          </div>
        </div>
        <span class="font-weight-bold text-lg text-primary">{{
          this.campaniaDescs
        }}</span>
      </div>
      <Timeline
        v-if="datos.length > 0"
        :dato="this.datos"
        :fecInis="
          transformDate(
            this.redSocialCamp[0].fecIni,
            userInfo.TIME_ZONE,
            'YYYY-MM-DD HH:mm:ss'
          )
        "
        :fecFinals="
          transformDate(
            this.redSocialCamp[0].fecFinal,
            userInfo.TIME_ZONE,
            'YYYY-MM-DD HH:mm:ss'
          )
        "
        :id_campanias="Number(this.$route.query.campaign)"
      ></Timeline>
      <Funnel :funneldata="funneldata"></Funnel>
      <div class="bg-white">
        <tabs :cant="3" :titles="tabs" @change="changeTab" />
        <div class="w-100 p-3">
          <action
            :fec_ini="fechaInicio"
            :fec_fin="fechaFinal"
            :timezoneId="userInfo.ID_ZONA_HORARIA"
            v-if="activeTab === 0 && userInfo"
          />
          <audience-conversion
            v-else-if="activeTab === 1 && userInfo"
            :idCampaniasx="this.idCampaniass"
          />
          <semantic-emotion
            :fec_ini="fechaInicio"
            :fec_fin="fechaFinal"
            v-else-if="activeTab === 2 && userInfo"
          />
        </div>
      </div>
    </div>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import {
  BreadCrumb,
  Tabs,
  CpTooltipWhiteCustom,
  AlertSetting,
  IscModalAlerts,
  UserObjMixin,
  Helpers,
  loaderCustom,
  mapActions,
} from "@isc/styleguide";
import Timeline from "./Timeline.vue";
import Action from "./tabs/Action.vue";
import AudienceConversion from "./tabs/AudienceConversion.vue";
import Funnel from "./Funnel.vue";
import SemanticEmotion from "./tabs/SemanticEmotion.vue";
export default {
  name: "Campaign",
  components: {
    BreadCrumb,
    Timeline,
    Funnel,
    Tabs,
    Action,
    AudienceConversion,
    SemanticEmotion,
    CpTooltipWhiteCustom,
    IscModalAlerts,
    loaderCustom,
  },
  data() {
    return {
      campaign: null,
      redSocialCamp: [],
      ver: "",
      marcaRes: "",
      postAll: [],
      fechaInicio: this.$route.query.fec_ini,
      fechaFinal: this.$route.query.fec_fin,
      fechaFinals: "",
      //id_campania: "",
      total: 0,
      datos: [],
      campaniaDescs: "",
      idCampaniass: "",
      idMarcas: "",
      activeTab: 0,
      tabs: [
        {
          name: "Action",
        },
        {
          name: "Audience & Conversion",
        },
        {
          name: "Semantic & Emotions",
        },
      ],
      funneldata: {
        facebook: {
          posts: 0,
          interactions: 0,
          impresions: 0,
          views: 0,
          new_audience: 0,
          addons: 0,
        },
        twitter: {
          posts: 0,
          interactions: 0,
          impresions: 0,
          views: 0,
          new_audience: 0,
          addons: 0,
        },
        instagram: {
          posts: 0,
          interactions: 0,
          impresions: 0,
          views: 0,
          new_audience: 0,
          addons: 0,
        },
        youtube: {
          posts: 0,
          interactions: 0,
          impresions: 0,
          views: 0,
          new_audience: 0,
          addons: 0,
        },
        linkedin: {
          posts: 0,
          interactions: 0,
          impresions: 0,
          views: 0,
          new_audience: 0,
          addons: 0,
        },
      },
      transformDate: (date, countryTz, format) =>
        Helpers.transformStringDate(date, countryTz, format),
      visibilityLoader: false,
    };
  },
  mixins: [AlertSetting, UserObjMixin],
  async mounted() {
    try {
      this.visibilityLoader = true;
      let idCampaign = this.$route.query.campaign;
      await this.setCurrentObjUser(); // function from styleguide (Mixin)
      this.idCampaniass = toString(this.$route.query.campaign);
      this.funneldata = await this.getDataSumPostHist(idCampaign) // funnelData
      this.datos = await this.getListPostDateID(idCampaign) // datos
      await this.listCampaignOne(idCampaign)
      await this.getListConversions({ idCampaign, idSocialMedia: 0 })
      await this.getAllSemantic(idCampaign)// tab 3 
    } catch (error) {
      console.error("[Error] in mounted Campaign.vue", error);
      throw error;
    } finally {
      this.visibilityLoader = false;
    }
  },
  methods: {
    ...mapActions({
      getSumPostHist: "campaign/campaign/GET_SUM_POST_HIST",
      getSocialCampaignOne: "campaign/campaign/GET_LIST_SOCIAL_CAMPAIGN_ONE",
      getListConversions: "campaign/campaign/GET_LIST_CONVERSIONS",
      getListPostDateID: "campaign/campaign/GET_LIST_POST_DATE_ID",
      getAllSemantic: "campaign/graphic/GET_ALL_SEMANTIC",
    }),
    changeTab(position) {
      this.activeTab = position;
    },
    async listCampaignOne(idCampaign) {
      try {
        this.redSocialCamp = await this.getSocialCampaignOne(idCampaign);
        if (this.redSocialCamp.length === 0) return;
        this.idMarcas = this.redSocialCamp[0].idMarcas;
        this.marcaRes = this.redSocialCamp[0].marcasRes;
        //this.id_campania = this.redSocialCamp[0].id_campania;
        this.fechaInicio = this.redSocialCamp[0].fecIni;
        this.fechaFinal = this.redSocialCamp[0].fecFinal;

        this.campaniaDescs = this.redSocialCamp[0].campaniaDesc;
        this.campaign = this.redSocialCamp[0];
      } catch (error) {
        console.log("[Error] in function  listCampaignOne ", error);
        throw error;
      }
    },
    //*Conversion Funnel data
    async getDataSumPostHist(idCampaign) {
      return await this.getSumPostHist({
        idCampaign,
        dateStart: this.fechaInicio,
        dateEnd: this.fechaFinal,
      });
    },
    abrirpestania(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
  },
};
</script>
<style scoped>
.contenedores {
  width: 100%;
  /* height: 882px; */
  margin: auto;
  background-color: #f3f4f7;
}
.cabecera {
  margin: auto;
  height: 35px;

  margin: 16px 0px 0px;
}
.left_label {
  text-align: left;
}
.right_label {
  margin: auto;
  height: 35px;
  width: 275px;
  margin-left: 635px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: #00a5ff;
  text-align: right !important;
  text-justify: inter-word;
}
.iconInfoCampView {
  font-size: 14px;
  color: #5d6b88;
  padding-left: 0.3rem;
}
</style>
