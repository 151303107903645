import Multiselect from "vue-multiselect";
import { LMap, LTileLayer } from "vue2-leaflet";
import L from "leaflet";
//* IMPORTANT: these two files match with plugin leaflet
//* But the most important the IconPie has values such as neg, neu, and pos. 
import IconCanvas from "@/lib/leaflet/icon_canvas";
import IconPie from "@/lib/leaflet/icon_pie";
//*********** */
import { geoInterestByCountry } from "@/services/sentiment.service";
import { loaderCustom, CpTooltipWhiteCustom, mapGetters, AlertSetting,
  IscModalAlerts  } from "@isc/styleguide";
export default {
  components: {
    Multiselect,
    LMap,
    LTileLayer,
    loaderCustom,
    CpTooltipWhiteCustom,
    IscModalAlerts
  },
  mixins:[ AlertSetting ], 
  created() {
    IconCanvas(L);
    IconPie(L);
  },
  data() {
    return {
      visibilityLoader: false,
      map: null,
      interesesP: [],
      markersLayer: new L.LayerGroup(), // NOTE: Layer is created here!
      fields: ["City", "Samples", "Sentiment", "Male", "Female", "Undefined"],
      table_items: [],
      sex_selected: "All",
      sex_options: [
        { text: "All", value: "All" },
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Undefined", value: "unk" },
      ],
      columns: [
        { property: "city",
          title: "City",
          collapseIcon: true, },
        { property: "samples",
          title: "Samples",
          collapseIcon: true, },
        { property: "sentiment",
          title: "Sentiment", },
        { property: "male",
          title: "Male", },
        { property: "female",
          title: "Female", },
        { property: "undefined",
          title: "Undefined",},
      ],
      rows: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 14,
      center: [-12.066565, -77.033457],
      bounds: null,
      multiSelect: [],
      country_value: [],
      country_options: [
        { name: "---select---", action: "---select---" },
        { name: "Perú", action: "peru", value: "2dfa9ecb0179a4e4" },
        { name: "Chile", action: "chile", value: "47a3cf27863714de" },
      ],
      interest_value: [],
      interest_options: [{ name: "Select All", checked: true, action: "all" }],
      drivers_value: [],
      drivers_options: [{ name: "Select All", checked: false, action: "all" }],
      donutGraph: {
        chart: { type: "pie",
          backgroundColor: "rgba(255, 255, 255, 0.0)", },
        credits: { enabled: false, },
        exporting: { enabled: false,},
        title: {text: null, },
        tooltip: {shared: true,
          useHTML: true,
          headerFormat: "<table>",
          pointFormat:'<tr><td style="padding:5px;color: {series.color}">{point.name}: </td>' +
            '<td style="padding:5px;text-align: right"><b>{point.percentage:.1f}%</b></td></tr>',
          footerFormat: "</table>", },
        plotOptions: {
          pie: {allowPointSelect: true,
            cursor: "pointer",
            innerSize: "50%",
            dataLabels: {
              color: "black",
              enabled: true,
              distance: -15, },
            showInLegend: false,
            size: "100%",
          },
        },
        series: [
          {
            colorByPoint: true,
            data: [
              { name: "Positivo",
                color: "#9fd895",
                y: 0,
                dataLabels: { format: "{point.percentage:.1f}%" }, },
              { name: "Neutral",
                color: "#828a96",
                y: 0,
                dataLabels: { format: "{point.percentage:.1f}%" },},
              { name: "Negativo",
                color: "#ff7f7f",
                y: 0,
                dataLabels: { format: "{point.percentage:.1f}%" },},
            ],
          },
        ],
      },
      clickButtonGo: false, // To show Table drivers, left to Donut graphic
      variableInterest: 1000,
      selectValueInterest: [],
      disableinteres: true,
      viewfilter: false, // To Show all right side data.
      isCountry: false,

    };
  },
  watch: {
    country_value(country){
      if(!country) return; 
      if(country.action !== "---select---"){
        this.isCountry = true;
      } else {
        this.isCountry = false;
      }
    },
  },
  methods: {
    limpiarCombo() {
      this.selectValueInterest = [];
    },
    limpiarComboDrivers() {
      this.drivers_options = []; },
    generatedri(keys){
      let drives=[]
        let arr=Object.keys(keys)
        arr.forEach(item=>{
          keys[item].forEach(it=>{
              drives.push({                
                  id:it+item,
                  interest:item,
                  name:it,
                  language:item                  
              })
          })
        })
      this.drivers_value=drives
    },
    async go() {
      this.rows=[]
      this.visibilityLoader = true;     
      this.multiSelect=[]
      this.drivers_value=[]
      try {
        this.markersLayer.clearLayers();
        //* Calling Service Interest
        const response = await geoInterestByCountry({
          country: this.country_value.action,
          interests: this.processInterest(),
          keywords: this.processDrivers(),
        }); 
        //console.log("RESPONSE INTEREST ==>",response)
        //* Get list of drivers 
        this.generatedri(response.items.keywords)
  
        const respDataMapObjects = this.setDataMap(response);
        let arrCitysData = [];
        //console.log("RESPONSE DATA MAP ==> ", respDataMapObjects);
        
        respDataMapObjects.forEach((itemCitys, indexCity) => {
          let objCity = {};
          objCity.id = indexCity;
          objCity.city = itemCitys.city;
          objCity.samples = itemCitys.total;
          let objGenderValid = { 
            typeSentiment: "", 
            gender: "", 
            value: 0, 
            count_male: 0, 
            count_female: 0,
            count_undefined: 0 
          };
          let arrSentiment = [];
          
          //* reading sentiments data 
          itemCitys.sentiments.forEach((itemSentiment, indexSentiment) => {
            let objSentiment = {};
            objSentiment.id = indexSentiment;
            objSentiment.city = itemCitys.city;
            // count total of sentiments (doc_count = 4;)
            objSentiment.samples = itemSentiment.doc_count;
            // Key (pos, neutral, neg) 
            objSentiment.key = itemSentiment.key;
            objSentiment.gender =
              itemSentiment["agg_terms_author_gender.keyword"].buckets;
            
            //* get major value doc_count sentiment  
            itemCitys.sentiments.forEach((itemTest) => {
              if (itemTest.doc_count > objGenderValid.value) {
                objGenderValid.value = itemTest.doc_count;
                objGenderValid.gender = itemTest.key;
                //objGenderValid.typeSentiment = itemSentiment.key;
                objGenderValid.typeSentiment = itemTest.key;
                objGenderValid.maxPerson = itemTest.doc_count;
                // objGenderValid.count_male = arrGendersPerson.find(
                //   (it) => it.key == "male"
                // ).doc_count;
                // objGenderValid.count_female = arrGendersPerson.find(
                //   (it) => it.key == "female"
                // ).doc_count;
                // objGenderValid.count_undefined = arrGendersPerson.find(
                //   (it) => it.key == "unk"
                // ).doc_count;  
              }
            });
            //console.log("Object value ", objGenderValid.value, itemSentiment.doc_count );
            //* if it has a gender count, retrieve it.
            //* It only takes genders from the main sentiment.
            if(objGenderValid.value === itemSentiment.doc_count){
              const arrGendersPerson = itemSentiment["agg_terms_author_gender.keyword"].buckets;
              objGenderValid.count_male = arrGendersPerson.find(  
                (it) => it.key === "male"
              ).doc_count;
              objGenderValid.count_female = arrGendersPerson.find(
                (it) => it.key === "female"
              ).doc_count;
              objGenderValid.count_undefined = arrGendersPerson.find(
                (it) => it.key === "unk"
              ).doc_count; 
            } 
  
            //console.log("Object gender valid ", objGenderValid, "index ", indexSentiment);
            // const respFindGenderMalePorc = arrGendersPerson.find(
            //   (it) => it.key == "male"
            // );
            // const respFindGenderFemalePorc = arrGendersPerson.find(
            //   (it) => it.key == "female"
            // );
            // const respFindGenderUndefinedPorc = arrGendersPerson.find(
            //   (it) => it.key == "unk"
            // );
            //Children percentages  
            //Variables
            let child_porc_male = 0; 
            let child_porc_female = 0; 
            let child_porc_undefined = 0; 
            if(objSentiment.samples > 0) {
              child_porc_male =
                Math.round((objGenderValid.count_male * 100) / objSentiment.samples);
              child_porc_female =
                Math.round((objGenderValid.count_female * 100) / objSentiment.samples);
              child_porc_undefined =
                Math.round((objGenderValid.count_undefined * 100) / objSentiment.samples);
            }
  
            objSentiment.porc_male = child_porc_male + "%";
            objSentiment.porc_female = child_porc_female + "%";
            objSentiment.porc_undefined = child_porc_undefined + "%";
            arrSentiment.push(objSentiment);
          });
          //console.log("Object gender valid ", objGenderValid);
          //console.log("Arr Sentiment ", arrSentiment);
          //* getting percentages by gender   
          const max_porc_male = Math.round(
            (objGenderValid.count_male * 100) / objGenderValid.maxPerson);
          const max_porc_female = Math.round(
            (objGenderValid.count_female * 100) / objGenderValid.maxPerson);
          const max_porc_undefined = Math.round(
            (objGenderValid.count_undefined * 100) / objGenderValid.maxPerson);
  
          objCity.children = arrSentiment;
          objCity.maxValueGender = objGenderValid.value;
          //objCity.maxTypeGender = objGenderValid.gender;
          objCity.typeSentiment = objGenderValid.typeSentiment;
          objCity.porc_male = max_porc_male + "%";
          objCity.porc_female = max_porc_female + "%";
          objCity.porc_undefined = max_porc_undefined + "%";
          arrCitysData.push(objCity);
        });
        
        this.rows = arrCitysData;
        this.clickButtonGo = true; // To show Table drivers, left to Donut graphic
        this.validate() 
        //console.log("DRIVERS ==>",this.drivers_value);
        //console.log("MULTISELECT ==> ",this.multiSelect);
        //console.log("INTEREST VALUE ==> ",this.interest_value);
        //console.log("ROWS ==> ",this.rows);
      } catch (error) {
        console.log("[Error] in function go", error);
        this.clickButtonGo = false; 
        this.errorModal(error.message)
      } finally {
        this.visibilityLoader = false;
        
      }
    },
    filtro_total_gender(gender, interes_sentimiento, sentiment) {
      let sentiment_count = 0;
      for (let i = 0; i < interes_sentimiento.length; i++) {
        if (
          interes_sentimiento[i][
            "agg_terms_sample_sentiment.keyword"
          ].buckets.filter((itemx) => itemx.key === sentiment)[0] !== undefined
        ) {
          interes_sentimiento[i]["agg_terms_sample_sentiment.keyword"].buckets
            .filter((itemx) => itemx.key === sentiment)[0]
            ["agg_terms_author_gender.keyword"].buckets.filter(
              (itemj) => itemj.key === gender
            )[0]
            ? (sentiment_count += interes_sentimiento[i][
                "agg_terms_sample_sentiment.keyword"
              ].buckets
                .filter((itemx) => itemx.key === sentiment)[0]
                ["agg_terms_author_gender.keyword"].buckets.filter(
                  (itemj) => itemj.key === gender
                )[0].doc_count)
            : (sentiment_count += 0);
        } else {
          sentiment_count += 0;
        }
      }
      return sentiment_count;
    },
    setDataMap(response) {
      try {
        let interes_sentimiento;
        let neu_sent = 0;
        let pos_sent = 0;
        let neg_sent = 0;
        let datoformat =
          response.items.data.aggregations.agg_geohash_grid_sample_geocode
            .buckets;
        switch (this.sex_selected) {
          case "unk":
            interes_sentimiento =
              response.items.data.aggregations[
                "agg_geohash_grid_sample_geocode"
              ].buckets;
            neu_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neu");
            pos_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "pos");
            neg_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neg");
            break;
          case "female":
            interes_sentimiento =
              response.items.data.aggregations[
                "agg_geohash_grid_sample_geocode"
              ].buckets;
            neu_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neu");
            pos_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "pos");
            neg_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neg");
            break;
          case "male":
            interes_sentimiento =
              response.items.data.aggregations[
                "agg_geohash_grid_sample_geocode"
              ].buckets;
            neu_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neu" );
            pos_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "pos");
            neg_sent = this.filtro_total_gender(
              this.sex_selected,
              interes_sentimiento,
              "neg" );
            break;
          default:
            interes_sentimiento =
              response.items.data.aggregations[
                "agg_terms_sample_sentiment.keyword"
              ].buckets;
            neu_sent = interes_sentimiento
              .filter((itemx) => itemx.key === "neu")
              .map(function (inntem) {
                return inntem.doc_count;});
            pos_sent = interes_sentimiento
              .filter((itemx) => itemx.key === "pos")
              .map(function (inntem) {
                return inntem.doc_count;});
            neg_sent = interes_sentimiento
              .filter((itemx) => itemx.key === "neg")
              .map(function (inntem) {
                return inntem.doc_count; });
        }
      
        //* Donut Graph design by data 
        let suma_sent_int = Math.round(
          parseInt(neu_sent) + parseInt(pos_sent) + parseInt(neg_sent) );
        this.donutGraph.series[0].data[0].y = Math.round(
          (parseInt(pos_sent) / suma_sent_int) * 100  );
        this.donutGraph.series[0].data[1].y = Math.round(
          (parseInt(neu_sent) / suma_sent_int) * 100 );
        this.donutGraph.series[0].data[2].y = Math.round(
          (parseInt(neg_sent) / suma_sent_int) * 100 );
        
          let objects = this.getObjects(datoformat);
        //console.log("OBJECTS ", objects);
        //Todo: Create all the markers for the map
        this.filterMap(this.sex_selected, objects);
        //* The Object LayerGroup (leaflet[Plugin])
        this.markersLayer.remove(); //(Layer) Removes the layer from the map it is currently active on.
        //* Adding all markers.
        this.markersLayer.addTo(this.map); // (Layer) Adds the layer to the given map or layer group.

        let sett = objects.map(function (mape) {
          return { g: mape.gender, k: mape.id_key, t: mape.total };
        });
        try {
          for (let mk_ = 0; mk_ < sett.length; mk_++) {
            if (
              typeof document.querySelectorAll(".leaflet-piechart-icon")[
                mk_
              ] === "undefined"
            ) {
            } else {
              document.querySelectorAll(".leaflet-piechart-icon")[
                mk_
              ].className = "leaflet-piechart-icon " + sett[mk_].g;
              document
                .querySelectorAll(".leaflet-piechart-icon")
                [mk_].setAttribute("data-number", sett[mk_].t);
            }
          }
        } catch (error2) {
        }
        try {
          objects = this.summaryByPlace(objects);
          this.table_items = [];
          objects.forEach((element) => {
            element.sentiments = this.getSentiments(element.sentiments);
            this.drawInterestTable(element);
          });
          return objects;
        } catch (error1) {
        }
      } catch (error) {
      }
    },
    summaryByPlace(objects) {
      try {
        let places = objects
          .map((place1) => {
            return objects.filter((place2, index) => {
              if (place1.place_id === place2.place_id) {
                delete objects[index];
                return place2;
              }
            });
          })
          .filter((el) => el != null);
        return places.map((el1) => {
          let total = 0;
          let pos = 0;
          let neu = 0;
          let neg = 0;
          let positive_un = 0;
          let positive_fe = 0;
          let positive_ma = 0;
          let neutral_un = 0;
          let neutral_fe = 0;
          let neutral_ma = 0;
          let negative_un = 0;
          let negative_fe = 0;
          let negative_ma = 0;
          for (let i = 0; i < el1.length; i++) {
            total += el1[i].total;
            let positive = el1[i].sentiments.filter(
              (sentiment) => sentiment.key === "pos"
            );
            let neutral = el1[i].sentiments.filter(
              (sentiment) => sentiment.key === "neu"
            );
            let negative = el1[i].sentiments.filter(
              (sentiment) => sentiment.key === "neg"
            );

            if (positive.length) {
              positive = positive[0];
              pos += positive.doc_count;
              let positive_u = positive[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "unk");
              let positive_m = positive[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "male");
              let positive_f = positive[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "female");
              positive_un += positive_u.length ? positive_u[0].doc_count : 0;
              positive_ma += positive_m.length ? positive_m[0].doc_count : 0;
              positive_fe += positive_f.length ? positive_f[0].doc_count : 0;
            }
            if (neutral.length) {
              neutral = neutral[0];
              neu += neutral.doc_count;
              let neutral_u = neutral[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "unk");
              let neutral_m = neutral[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "male");
              let neutral_f = neutral[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "female");
              neutral_un += neutral_u.length ? neutral_u[0].doc_count : 0;
              neutral_ma += neutral_m.length ? neutral_m[0].doc_count : 0;
              neutral_fe += neutral_f.length ? neutral_f[0].doc_count : 0;
            }
            if (negative.length) {
              negative = negative[0];
              neg += negative.doc_count;
              let negative_u = negative[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "unk");
              let negative_m = negative[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "male");
              let negative_f = negative[
                "agg_terms_author_gender.keyword"
              ].buckets.filter((gender) => gender.key === "female");
              negative_un += negative_u.length ? negative_u[0].doc_count : 0;
              negative_ma += negative_m.length ? negative_m[0].doc_count : 0;
              negative_fe += negative_f.length ? negative_f[0].doc_count : 0;
            }
          }
          return {
            city: el1[0].city,
            place_id: el1[0].place_id,
            total: total,
            sentiments: [
              {
                key: "pos",
                doc_count: pos,
                "agg_terms_author_gender.keyword": {
                  buckets: [
                    { key: "unk", doc_count: positive_un },
                    { key: "male", doc_count: positive_ma },
                    { key: "female", doc_count: positive_fe },
                  ],
                },
              },
              {
                key: "neu",
                doc_count: neu,
                "agg_terms_author_gender.keyword": {
                  buckets: [
                    { key: "unk", doc_count: neutral_un },
                    { key: "male", doc_count: neutral_ma },
                    { key: "female", doc_count: neutral_fe },
                  ],
                },
              },
              {
                key: "neg",
                doc_count: neg,
                "agg_terms_author_gender.keyword": {
                  buckets: [
                    { key: "unk", doc_count: negative_un },
                    { key: "male", doc_count: negative_ma },
                    { key: "female", doc_count: negative_fe },
                  ],
                },
              },
            ],
          };
        });
      } catch (error) {
      }
    },
    getObjects(datoformat) {
      return datoformat.map(function (item) {
        let key_id = item.key;
        let total = item.doc_count;
        let city =
          typeof item["agg_terms_sample_place_name.keyword"].buckets[0] !==
          "undefined"
            ? item["agg_terms_sample_place_name.keyword"].buckets[0].key
            : "Unknown";
        let place_id =
          typeof item["agg_terms_sample_place_id.keyword"].buckets[0] !==
          "undefined"
            ? item["agg_terms_sample_place_id.keyword"].buckets[0].key
            : "Unknown";
        let latitude = item.agg_geo_centroid_sample_geocode.location.lat;
        let longitude = item.agg_geo_centroid_sample_geocode.location.lon;
        let sentiments = item["agg_terms_sample_sentiment.keyword"].buckets;
        let sentiment = item[
          "agg_terms_sample_sentiment.keyword"
        ].buckets.reduce((prev, current) =>
          prev.doc_count > current.doc_count ? prev : current
        );
        let gender_sent_color = sentiment.key;
        let gender = item["agg_terms_author_gender.keyword"].buckets[0].key;
        let gender_count =
          item["agg_terms_author_gender.keyword"].buckets[0].doc_count;
        //contadores de total de interacciones segun genero
        let total_u = 0;
        let total_f = 0;
        let total_m = 0;
        for (
          let i = 0;
          i < item["agg_terms_sample_sentiment.keyword"].buckets.length;
          i++
        ) {
          for (
            let x = 0;
            x <
            item["agg_terms_sample_sentiment.keyword"].buckets[i][
              "agg_terms_author_gender.keyword"
            ].buckets.length;
            x++
          ) {
            let buckets_genders =
              item["agg_terms_sample_sentiment.keyword"].buckets[i][
                "agg_terms_author_gender.keyword"
              ].buckets[x];
            buckets_genders["key"] === "unk"
              ? (total_u += buckets_genders.doc_count)
              : (total_u += 0);
            buckets_genders["key"] === "female"
              ? (total_f += buckets_genders.doc_count)
              : (total_f += 0);
            buckets_genders["key"] === "male"
              ? (total_m += buckets_genders.doc_count)
              : (total_m += 0);
          }
        }
        return {
          id_key: key_id,
          city: city,
          longitude: longitude,
          latitude: latitude,
          gender: gender,
          pie_color: gender_sent_color,
          total: total,
          sentiments: sentiments,
          place_id: place_id,
          total_m: total_m,
          total_f: total_f,
          total_u: total_u,
        };
      });
    },
    getSentiments(data) {
      try {
        let sentiment_positive = {
          key: "pos",
          doc_count: 0,
          "agg_terms_author_gender.keyword": {
            buckets: [
              { key: "male", doc_count: 0 },
              { key: "female", doc_count: 0 },
              { key: "unk", doc_count: 0 },
            ],
          },
        };
        let sentiment_neutral = {
          key: "neu",
          doc_count: 0,
          "agg_terms_author_gender.keyword": {
            buckets: [
              { key: "male", doc_count: 0 },
              { key: "female", doc_count: 0 },
              { key: "unk", doc_count: 0 },
            ],
          },
        };
        let sentiment_negative = {
          key: "neg",
          doc_count: 0,
          "agg_terms_author_gender.keyword": {
            buckets: [
              { key: "male", doc_count: 0 },
              { key: "female", doc_count: 0 },
              { key: "unk", doc_count: 0 },
            ],
          },
        };
        let positive = data.filter((element) => element.key === "pos");
        let neutral = data.filter((element) => element.key === "neu");
        let negative = data.filter((element) => element.key === "neg");
        return [
          positive.length ? positive[0] : sentiment_positive,
          neutral.length ? neutral[0] : sentiment_neutral,
          negative.length ? negative[0] : sentiment_negative,
        ];
      } catch (error) {
      }
    },
    filterMap(gender, objects) {
      objects.forEach((element) => {
        let total;
        let doc_count_gender;
        switch (gender) {
          case "unk":
            total = element.total_u;
            break;
          case "female":
            total = element.total_f;
            break;
          case "male":
            total = element.total_m;
            break;
          default:
            total = element.total;
        }
        if (total > 0) {
          //si el total es 0, el grafico no aparece en el mapa (no existe informaciÃ³n)
          let lat_market = element.latitude;
          let lon_market = element.longitude;
          let genero_sent_market = element.sentiments;
          let count_total = total;
          let city = element.city;
          let porcen_sent = genero_sent_market.map(function (task) {
            let array_genders;
            switch (gender) {
              case "unk":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key === "unk")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              case "female":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key === "female")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              case "male":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key === "male")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              default:
                doc_count_gender = task.doc_count;
            }
            let perc_sent = Math.round((doc_count_gender * 100) / count_total);
            if (perc_sent > 100) perc_sent = 100;
            return {
              nombre: task.key,
              value: perc_sent,
              total: total,
              ancho: count_total / 2,
              general: objects[0].total,
            };
          });
          //* Map Markets
          let mrkts = genero_sent_market.map(function (task) {
            let array_genders;
            switch (gender) {
              case "unk":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key == "unk")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              case "female":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key == "female")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              case "male":
                array_genders = task[
                  "agg_terms_author_gender.keyword"
                ].buckets.filter((itemx) => itemx.key == "male")[0];
                array_genders
                  ? (doc_count_gender = array_genders.doc_count)
                  : (doc_count_gender = 0);
                break;
              default:
                doc_count_gender = task.doc_count;
            }
            let perc_sent = Math.round((doc_count_gender * 100) / count_total);
            if (perc_sent > 100) perc_sent = 100;
            let sent_key_text;
            if (task.key === "pos") {
              sent_key_text = "Positive";
            } else if (task.key === "neg") {
              sent_key_text = "Negative";
            } else if (task.key === "neu") {
              sent_key_text = "Neutral";
            }
            if (perc_sent > 0) {
              return (
                '<div class="pop_in_bx ' + task.key + '">' +
                '<span class="name_">' + sent_key_text +"</span>" +
                '<span class="box_">' +'<span class="perc_" style="width:' +
                perc_sent + '%"></span>' + "</span>" +
                `<span class="val_">${perc_sent}%</span>` +"</span>" + "</div>"
              );
            }
          });
          //este bloque de codigo dibuja los graficos y sus popup's en el mapa
          let markerIcon = L.piechartMarker(
            L.latLng([lat_market, lon_market]),
            { data: porcen_sent }
          ).bindPopup(
            '<div class="over-info"><h1><span class="pop_name">' +
              city +
              '<br></span><span class="po_name_val">' +
              count_total +
              '</span><div class="clearfix"></div></h1><div class="add_prc">' +
              mrkts.join(" ") +
              "</div></div>"
          );
          this.markersLayer.addLayer(markerIcon);
        }
      });
      
    },
    onReady() {
      this.map = this.$refs.myMap.mapObject;
    },
    getGenders(genders) {
      let gendersBySentiment =
        genders["agg_terms_author_gender.keyword"].buckets;
      let gender_m = gendersBySentiment.filter((item) => item.key === "male");
      let gender_f = gendersBySentiment.filter((item) => item.key === "female");
      let gender_u = gendersBySentiment.filter((item) => item.key === "unk");
      return {
        gender_m: gender_m.length
          ? gender_m[0].doc_count > 0
            ? Math.round((gender_m[0].doc_count / genders.doc_count) * 100)
            : 0
          : 0,
        gender_f: gender_f.length
          ? gender_f[0].doc_count > 0
            ? Math.round((gender_f[0].doc_count / genders.doc_count) * 100)
            : 0
          : 0,
        gender_u: gender_u.length
          ? gender_u[0].doc_count > 0
            ? Math.round((gender_u[0].doc_count / genders.doc_count) * 100)
            : 0
          : 0,
      };
    },
    drawInterestTable(element) {
      try {
        let max_gender = element.sentiments.reduce((prev, current) =>
          prev.doc_count > current.doc_count ? prev : current
        );
        let genders = this.getGenders(max_gender);

        this.table_items.push({
          City: element.city,
          Samples: element.total,
          Sentiment: max_gender.key,
          Male: genders.gender_m + "%",
          Female: genders.gender_f + "%",
          Undefined: genders.gender_u + "%",
        });
        //TODO: BUSCAR COMO MOSTRAR SUBTABLA
        const subTabla = element.sentiments.map((sentiment) => {
          let _genders = this.getGenders(sentiment);
          return {
            City: "",
            Samples: element.doc_count,
            Sentiment: element.key,
            Male: _genders.gender_m,
            Female: _genders.gender_f,
            Undefined: _genders.gender_u,
          };
        });
      } catch (error) { }
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    processInterest() {
      try {
        const respFilterInterest = this.interest_value.filter(
          (it) => it.action == "all" );
        const dataCompare =respFilterInterest.length > 0? this.interesesP.filter((it) => it.action !== "all") : this.interest_value;
        return dataCompare.map((io) => io.name);
      } catch (error) {
        return [];
      }
    },
    processDrivers() {
      return this.drivers_value.map((d) => d.name);
    },
    dispatchAction(actionName) {
      if (actionName.action && actionName.action === "all") {
        this.interest_value = [];
        this.interesesP.forEach((opt) => {
          opt.checked = true;
        });
        this.interest_value = this.interesesP.map((i) => i);
        this.selectValueInterest = this.interest_value;
      } else {
        const respFilterValid = this.selectValueInterest.filter(
          (it) => it.action === actionName.action
        );
        if (respFilterValid.length === 0) {
          this.selectValueInterest.push(actionName);
        }
        const index = this.interesesP.findIndex(
          (opt) => opt.name === actionName.name
        );
        this.interesesP[index].checked = true;
      }
    },
    removeAction(actionName) {
      if (actionName.action && actionName.action === "all") {
        this.limpiarCombo()
        this.interesesP.forEach((opt) => {
          opt.checked = false;
        });
        this.interest_value = this.interesesP.map((i) => i);
      } else {
        const index = this.interesesP.findIndex(
          (opt) => opt.name === actionName.name
        );
        this.interesesP[index].checked = false;
      }
    },
    dispatchActionCountry(country) {
      const centers = {
        peru: [-12.066565, -77.033457],
        chile: [-33.47269, -70.64724],
      };
      if (country.action == "peru" || country.action == "chile") {       
        if(this.interesesP.length === 0){
          Object.keys(this.drivers[country.action].categories).forEach((item) => {
            this.interesesP.push({
              name: item,
              checked: false,
              action: item,
              value: item,
            });
          });
        }
        this.disableinteres = false;
        this.center = centers[country.action];
      } else {
        this.center = centers["peru"];
        this.disableinteres = true;
      }
    },
    async listadoDriver() {
      //let drivers = JSON.parse(localStorage.getItem("Drivers"));
      this.limpiarComboDrivers();
      let arrDrivers = [];
      let arrSelectMultiLeyenda = [];
      const respFilterInterest = this.selectValueInterest.filter(
        (it) => it.action !== "all" && it.checked == true
      );
      if(respFilterInterest.length>0 && this.country_value.action!="all"){
        respFilterInterest.forEach(item =>{
          let groupfordrive={};
          groupfordrive.language=item.action
          let groupkeys=[]
         this.drivers[this.country_value.action].categories[item.action].forEach(it=>{
           if(it.term){
            groupkeys.push({
             id:it.id,
             name:it.term,
             interest:item.action ,
             checked:true           
           })}
         })
         groupfordrive.libs=groupkeys
         arrSelectMultiLeyenda.push(groupfordrive)
         arrDrivers.push(groupfordrive)
        })  
      }
      this.drivers_options = arrDrivers   
      this.multiSelect = arrSelectMultiLeyenda;
    },
    validate(){
      if(this.interest_value.length>0 || this.rows.length>0 || this.multiSelect.length>0 || this.drivers_value.length>0){           
        this.viewfilter=true
      }
    }
  },
  computed: {
    calcInterest() {
      const selected = this.interesesP.filter((i) => i.checked).length;
      if (selected === this.interesesP.length) {
        return selected - 1;
      } else {
        return selected;
      }
    },
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff",
      drivers: "global/helper/getDrivers",
    }),
  },
  mounted() {
    console.log("Drivers ", this.drivers);
    this.limpiarCombo();
  },
};