<template>
  <div>
    <div class="confirmation-content">
      <div class="row mb-4">
        <div class="w-100 text-primary my-4 text-center font-weight-bold">
          Confirmation
        </div>
        <div id="table-content-campaign">
          <table class="table">
            <thead>
              <tr>
                <th
                  colspan="7"
                  class="border-0 text-center bg-primary text-white"
                >
                  Campaign
                </th>
              </tr>
            </thead>
            <tbody class="text-xs">
              <tr class="font-weight-bold text-secondary">
                <td>Profile</td>
                <td>Name</td>
                <td>Start</td>
                <td>End</td>
                <td>Budget</td>
                <td>Type</td>
              </tr>
              <tr class="bg-mainbg">
                <td>{{ campaign.account_name }}</td>
                <td>{{ campaign.name }}</td>
                <td>{{ campaign.dates.start }}</td>
                <td>{{ campaign.dates.end }}</td>
                <td>{{ campaign.currency }} {{ campaign.budget }}</td>
                <td>{{ campaign.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="buttons-content my-5">
        <b-button
          type="button"
          variant="mainbg"
          class="px-4 mx-4 shadow-sm"
          @click="goToBudgetType()"
          >Prev</b-button
        >
        <b-button
          type="button"
          @click="saveCampaign()"
          variant="primary"
          class="px-4 mx-4 shadow-sm"
          >Finish</b-button
        >
      </div>
    </div>
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>
<script>
import { AlertSetting, IscModalAlerts, mapActions  } from "@isc/styleguide";
export default {
  name: "Confirmation",
  components: {
    IscModalAlerts
  },
  props: ["campaign", "submit"],
  mixins: [AlertSetting ],
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      insertCampaign: "settings/monitor/INSERT_CAMPAIGN",
    }),
    goToBudgetType() {
      this.$emit("prev", true);
    },
    async saveCampaign() {
      try {
        this.$emit('active-loader');
        const resultInsertCampaign = await this.insertCampaign({ data: this.campaign});
        const { data } = resultInsertCampaign.data;
        if (!data) {
          this.$emit("inactive-loader");
          return;
        }
        if (data.codigo == 200) {
          this.$emit("closeModal", true);
          this.$emit("messageCreated", true);
        } 
      } catch (error) {
        console.log("[Error] in function saveCampaign Service", error?.response?.data.message);
        this.$emit('inactive-loader');
        let message = error?.response?.data.message ?? "Server fails.";
        this.errorModal(message);
      }
    },
  },
};
</script>
<style scoped>
#table-content-campaign {
  text-align: center;
  margin: 0 auto;
}
.confirmation-content {
  text-align: center;
}
</style>
