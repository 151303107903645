var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "confirmation-content" }, [
        _c("div", { staticClass: "row mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "w-100 text-primary my-4 text-center font-weight-bold",
            },
            [_vm._v(" Confirmation ")]
          ),
          _c("div", { attrs: { id: "table-content-campaign" } }, [
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _c("tbody", { staticClass: "text-xs" }, [
                _vm._m(1),
                _c("tr", { staticClass: "bg-mainbg" }, [
                  _c("td", [_vm._v(_vm._s(_vm.campaign.account_name))]),
                  _c("td", [_vm._v(_vm._s(_vm.campaign.name))]),
                  _c("td", [_vm._v(_vm._s(_vm.campaign.dates.start))]),
                  _c("td", [_vm._v(_vm._s(_vm.campaign.dates.end))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.campaign.currency) +
                        " " +
                        _vm._s(_vm.campaign.budget)
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.campaign.type))]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "buttons-content my-5" },
          [
            _c(
              "b-button",
              {
                staticClass: "px-4 mx-4 shadow-sm",
                attrs: { type: "button", variant: "mainbg" },
                on: {
                  click: function ($event) {
                    return _vm.goToBudgetType()
                  },
                },
              },
              [_vm._v("Prev")]
            ),
            _c(
              "b-button",
              {
                staticClass: "px-4 mx-4 shadow-sm",
                attrs: { type: "button", variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.saveCampaign()
                  },
                },
              },
              [_vm._v("Finish")]
            ),
          ],
          1
        ),
      ]),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "border-0 text-center bg-primary text-white",
            attrs: { colspan: "7" },
          },
          [_vm._v(" Campaign ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "font-weight-bold text-secondary" }, [
      _c("td", [_vm._v("Profile")]),
      _c("td", [_vm._v("Name")]),
      _c("td", [_vm._v("Start")]),
      _c("td", [_vm._v("End")]),
      _c("td", [_vm._v("Budget")]),
      _c("td", [_vm._v("Type")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }