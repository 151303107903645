<template>
  <div class="semantic">
    <loader-custom v-if="visibilityLoader"></loader-custom>
    <div class="col-md-12">
      <b-row class="container-semantic">
        <b-col>
          <span
            class="font-weight-bold text-capitalize text-lsm text-secondary mr-1 ml-1"
          >
            Semantic Analysis Action
          </span>
          <span class="text-secondary">
            <i id="impact1" class="fa fa-info-circle text-sm text-skygray"></i>
            <CpTooltipDoubleCustom
              target="impact1"
              tooltipTitle="Tooltip title"
              title="Update: Every day (9pm)"
              subTitle2="Semantic analysis of the feeling based on the most important words of the comments in the publication."
              heightSubTitle1="46px"
              heightSubTitle2="77px"
            />
          </span>
        </b-col>
        <b-col class="pr-0">
          <div class="panel-selects">
            <div class="panel-1">
              <label
                class="mr-sm-2 text-xs text-secondary text-label-semantic"
                for="inline-form-custom-select-pref"
                >Social Network:</label
              >
              <multi-select-custom
                class="selected-sn"
                :selected="selectnetworks"
                :options="optionsnetworks"
                @selectValue="consultarActions"
              />
              <!--  <b-form-select id="inline-form-custom-select-pref" class="select-style mb-2 mr-sm-2 mb-sm-0 select-actions select-format" :options="optionsnetworks" v-model="selectnetworks"
                @change="consultarActions()"></b-form-select> -->
            </div>
            <div class="panel-2">
              <label
                class="mr-sm-2 text-xs text-secondary text-label-semantic"
                for="inline-form-custom-select-pref select-format"
                >Actions:</label
              >

              <multi-select-custom
                class="selected-sn"
                :selected="selectactions"
                :options="optionsactions"
                @selectValue="ConsultarSegunAction"
                :disabled="disableactions"
              />
              <!-- <b-form-select  :disabled="disableactions" id="inline-form-custom-select-pref" 
              class="mb-2 mr-sm-0 mb-sm-0 select-segun-actions select-format" :options="optionsactions" v-model="selectactions"
                disabled-field="notEnabled" @change="ConsultarSegunAction()"></b-form-select> -->
            </div>
          </div>
        </b-col>
      </b-row>
      <div
        class="w-100 image-container-off"
        :class="['temp', view == true ? 'display' : '']"
      >
        <img
          src="../../../../assets/all-images/semantic.png"
          style="width: 100%; height: 100%"
          alt=""
        />
        <div class="image-container-off__message msg-off-semantic">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOffGraphic }}
        </div>
      </div>
      <div :class="[view == false ? 'display' : '']" id="mynetwork"></div>
      <div>
        <div class="col-md-12 emotion-class">
          <div style="float: right">
            <label
              class="mr-sm-2"
              style="
                margin-top: 30px;
                font-family: Oxygen;
                font-size: 15px;
                font-weight: bold;
              "
              for="inline-form-custom-select-pref"
              >Emotion Analysis
              <div class="tooltip_st">
                <i class="fa fa-info-circle tlt-ico" id="emotion-analysis"></i>
                <CpTooltipWhiteCustom
                  target="emotion-analysis"
                  message="Analysis of daily emotions by campaign and social network"
                />

                <span class="ttl-text">
                  <div class="arrow-up"></div>
                </span>
              </div>
            </label>
            <br />
            <multi-select-custom
              class="selected-emotion"
              :selected="selectedDefault"
              :options="optionsemotions"
              @selectValue="selectEmotion"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <highcharts v-if="sentimentview" :options="optionsSecond"></highcharts>
      <div v-else class="image-container-off">
        <img
          class="opacity-50"
          src="../../../../assets/all-images/sentiment-off.png"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOffGraphic }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Network } from "vis-network/peer/esm/vis-network";
import { DataSet } from "vis-data/peer/esm/vis-data";
import {
  CpTooltipDoubleCustom,
  CpTooltipWhiteCustom,
  MultiSelectCustom,
  mapGetters,
  loaderCustom,
  mapActions,
} from "@isc/styleguide";
export default {
  name: "semantic-emotiobs",
  components: {
    CpTooltipDoubleCustom,
    CpTooltipWhiteCustom,
    MultiSelectCustom,
    loaderCustom,
  },
  data() {
    return {
      visibilityLoader: false,
      rangeDate: [moment().subtract(15, "days").toDate(), moment().toDate()],
      optionsnetworks: [
        { text: "-- Select --", value: null },
        { text: "All", value: "all" },
        { text: "Facebook", value: "fb" },
        { text: "Instagram", value: "ig" },
        { text: "LinkedIn", value: "ln" },
        { text: "Twitter", value: "tw" },
        { text: "YouTube", value: "yt" },
      ],
      optionsactions: [{ text: "-- Select --", value: null }],
      //selectnetworks: null,
      selectnetworks: { text: "-- Select --", value: null },
      //selectedNetworkDefault: { text: 'All', value: 'all' },
      //selectedActions: { text: 'All', value: 'all' },
      //selectactions: null,
      selectactions: { text: "-- Select --", value: null },
      disableactions: true,
      selectedDefault: { text: "All", value: "all" },
      idcampania: "",
      optionsSecond: {
        chart: {
          type: "spline",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          marginTop: 20,

          height: 360,
          width: 1041,
          marginBottom: 80,
          marginLeft: 60,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: "datetime",
          lineColor: "#D6DBDF",
          tickLength: 0,
          categories: [],
        },
        credits: {
          text: "",
        },
        yAxis: {
          labels: {
            style: {
              color: "#B3B4B8",
            },
          },
          min: 0,
          title: {
            text: null,
            enabled: false,
            gridLineColor: "#D5DAE0",
          },
          stackLabels: {
            enabled: false,
          },
        },
        legend: {
          align: "right",
          x: 10,
          verticalAlign: "bottom",
          y: 10,
          floating: true,
          borderWidth: 0,
          shadow: false,
          symbolWidth: 1,
          itemStyle: {
            fontWeight: "normal",
            fontSize: "10px",
          },
        },
        tooltip: {
          formatter: function () {
            let text =
              "<table class='table_tooltip' border='0' cellspacing='0' cellpadding='0'>";
            text +=
              "<tr><td colspan='2' class='tp-table'>" + this.x + "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: false,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        exporting: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointWidth: 5,
            allowPointSelect: true,
          },
          bar: {
            stacking: "normal",
            pointWidth: 7,
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
            },
            pointWidth: 7,
          },
        },
        series: [],
      },
      optionsemotions: [
        { text: "All", value: "all" },
        { text: "Fearful", value: "fearful" },
        { text: "Angry", value: "angry" },
        { text: "Neutral", value: "neutral" },
        { text: "Happy", value: "happy" },
        { text: "Sad", value: "sad" },
        { text: "Surprised", value: "surprised" },
        { text: "Disgusted", value: "disgusted" },
      ],
      tempemotions: [],
      enable: false,
      view: false,
      sentimentview: true,
      timezoneId: null,
    };
  },
  props: {
    fec_ini: String,
    fec_fin: String,
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
      msgImgOffGraphic: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
      allSemantic: "campaign/graphic/getSemantic",
    }),
  },
  methods: {
    ...mapActions({
      getAllSemanticBySocialMedia:
        "campaign/graphic/GET_ALL_SEMANTIC_BY_SOCIAL_MEDIA",
      getAllSemanticByPost: "campaign/graphic/GET_ALL_SEMANTIC_BY_POST",
      getActions: "campaign/graphic/GET_ACTIONS",
      getDataSentimentGraph: "campaign/graphic/GET_SENTIMENT_GRAPH",
    }),
    consultAllSemantics() {
      const semantic = this.allSemantic;
      //console.log("SEMANTIC =====> ", semantic);
      let container = document.getElementById("mynetwork");
      this.view =
        semantic.data.items.length > 0 || semantic.data.edges.length > 0
          ? true
          : false;
      let nodes = new DataSet(semantic.data.items);
      let edges = new DataSet(semantic.data.edges);

      let data = {
        nodes: nodes,
        edges: edges,
      };

      let options = semantic.options;
      let network = new Network(container, data, options);
    },
    async consultAllSemanticsXRedSocial(idCampaign, rss) {
      const result = await this.getAllSemanticBySocialMedia({
        idCampaign,
        rss,
      });
      let container = document.getElementById("mynetwork");
      this.view =
        result.data.items.length > 0 || result.data.edges.length > 0
          ? true
          : false;
      let nodes = new DataSet(result.data.items);
      let edges = new DataSet(result.data.edges);

      let data = {
        nodes: nodes,
        edges: edges,
      };
      let options = result.options;
      let network = new Network(container, data, options);
    },
    async consultAllSemanticsXPost(idPost) {
      try {
        const result = await this.getAllSemanticByPost(idPost);
  
        let container = document.getElementById("mynetwork");
        this.view =
          result.data.items.length > 0 || result.data.edges.length > 0
            ? true
            : false;
        let nodes = new DataSet(result.data.items);
        let edges = new DataSet(result.data.edges);
  
        let data = {
          nodes: nodes,
          edges: edges,
        };
  
        let options = result.options;
        let network = new Network(container, data, options);
        
      } catch (error) {
        console.error("[Error] in function: consultAllSemanticsXPost ", error);
        throw error;
      }
    },
    //* SN = Social Network
    async consultarActions(actionSn) {
      try {
        this.selectnetworks = actionSn;
        switch (this.selectnetworks.value) {
          case null:
            this.disableactions = true;
            this.selectactions = { text: "-- Select --", value: null };
            break;
          case "fb":
            await this.contruirSelectActions("fb");
            this.disableactions = false;
            this.selectactions = { text: "-- Select --", value: null };
            this.setgraphSentiment(this.idcampania, "rss", 1);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "fb");
            break;
          case "tw":
            await this.contruirSelectActions("tw");
            this.disableactions = false;
            this.selectactions = { text: "-- Select --", value: null };
            this.setgraphSentiment(this.idcampania, "rss", 2);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "tw");
            break;
          case "ig":
            await this.contruirSelectActions("ig");
            this.disableactions = false;
            this.selectactions = { text: "-- Select --", value: null };
            this.setgraphSentiment(this.idcampania, "rss", 3);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "ig");
            break;
          case "yt":
            await this.contruirSelectActions("yt");
            this.disableactions = false;
            this.selectactions = { text: "-- Select --", value: null };
            this.setgraphSentiment(this.idcampania, "rss", 4);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "yt");
            break;
          case "ln":
            await this.contruirSelectActions("ln");
            this.disableactions = false;
            this.selectactions = { text: "-- Select --", value: null };
            this.setgraphSentiment(this.idcampania, "rss", 5);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "ln");
            break;
          case "all":
            await this.contruirSelectActions("all");
            this.disableactions = false;
            this.enable = true;
            this.consultAllSemantics();
            this.setgraphSentiment(this.idcampania, "campaign");
            this.selectactions = { text: "-- Select --", value: null };
            break;
          default:
            this.disableactions = true;
            this.selectactions = { text: "No Data", value: "void" };
            break;
        }
        
      } catch (error) {
        console.error("[Error] in function: consultarActions ", error);
        throw error;
      }
    },
    async contruirSelectActions(rss) {
      try {
        this.clear();
        const response = await this.getActions({
          idCampaign: this.idcampania,
          rss,
        });
        if (response.length > 0) {
          this.optionsactions.push({ text: "All", value: "all" });
          response.forEach((element) => {
            this.optionsactions.push({
              text: element.descripcion,
              value: element.id_post_red_social,
            });
          });
        } else {
          this.disableactions = true;
          this.optionsactions.push({ text: "No Data", value: "void" }),
            (this.selectactions = { text: "No Data", value: "void" });
        }
        
      } catch (error) {
        console.error("[Error] in function: contruirSelectActions ", error);
        throw error;
      }
    },
    async clear() {
      this.optionsactions = [{ text: "-- Select --", value: null }];
    },
    async ConsultarSegunAction(action) {
      try {
        this.selectactions = action;
        switch (this.selectactions.value) {
          case null:
            break;
          case "void":
            break;
          case "all":
            this.enable = true;
            await this.consultarActionsforAll();
            break;
          default:
            this.enable = true;
            await this.consultAllSemanticsXPost(this.selectactions.value);
            this.setgraphSentiment(this.selectactions.value, "post");
            break;
        }
        
      } catch (error) {
        console.error("[Error] in function: ConsultarSegunAction ", error);
        throw error;
      }
    },
    async consultarActionsforAll() {
      try {
        switch (this.selectnetworks.value) {
          case null:
            this.disableactions = true;
            this.selectactions = { text: "-- Select --", value: null };
            break;
          case "fb":
            this.setgraphSentiment(this.idcampania, "rss", 1);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "fb");
            break;
          case "tw":
            this.setgraphSentiment(this.idcampania, "rss", 2);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "tw");
            break;
          case "ig":
            this.setgraphSentiment(this.idcampania, "rss", 3);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "ig");
            break;
          case "yt":
            this.setgraphSentiment(this.idcampania, "rss", 4);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "yt");
            break;
          case "ln":
            this.setgraphSentiment(this.idcampania, "rss", 5);
            await this.consultAllSemanticsXRedSocial(this.idcampania, "ln");
            break;
          case "all":
            await this.contruirSelectActions("all");
            this.consultAllSemantics();
            this.setgraphSentiment(this.idcampania, "campaign");
            break;
          default:
            this.disableactions = true;
            this.selectactions = { text: "No Data", value: "void" };
            break;
        }
        
      } catch (error) {
        console.error("[Error] in function: consultarActionsforAll ", error);
        throw error;
      }
    },
    async setgraphSentiment(id, type, idrss) {
      try {
        this.tempemotions = await this.getDataSentimentGraph({
          id, 
          type, 
          rss: idrss,
          fechaini: this.fec_ini,
          fechafin: this.fec_fin,
          timezone_id: this.timezoneId
        });
        let primero = 0;
        let segundo = 0;
        let tercero = 0;
        let cuarto = 0;
        let quinto = 0;
        let sexto = 0;
        let septimo = 0;
        primero = Object.values(this.tempemotions.series[0].data).reduce(
          (a, b) => a + b
        );
        segundo = Object.values(this.tempemotions.series[1].data).reduce(
          (a, b) => a + b
        );
        tercero = Object.values(this.tempemotions.series[2].data).reduce(
          (a, b) => a + b
        );
        cuarto = Object.values(this.tempemotions.series[3].data).reduce(
          (a, b) => a + b
        );
        quinto = Object.values(this.tempemotions.series[4].data).reduce(
          (a, b) => a + b
        );
        sexto = Object.values(this.tempemotions.series[5].data).reduce(
          (a, b) => a + b
        );
        septimo = Object.values(this.tempemotions.series[6].data).reduce(
          (a, b) => a + b
        );
  
        if (
          primero > 0 ||
          segundo > 0 ||
          tercero > 0 ||
          cuarto > 0 ||
          quinto > 0 ||
          sexto > 0 ||
          septimo > 0
        ) {
          this.sentimentview = true;
        } else {
          this.sentimentview = false;
        }
        this.optionsSecond.series = this.tempemotions.series || [];
        this.optionsSecond.xAxis.categories = this.tempemotions.categories || [];
        
      } catch (error) {
        console.error("[Error] in function: setgraphSentiment ", error);
        throw error;
      }
    },
    async selectEmotion(selectedEmotion) {
      let seriestemp = [];
      switch (selectedEmotion.value) {
        case "fearful":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Fearful"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "angry":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Angry"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "neutral":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Neutral"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "happy":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Happy"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "sad":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Sad"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "surprised":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Surprise"
          );
          this.optionsSecond.series = seriestemp || [];
          break;
        case "disgusted":
          seriestemp = this.tempemotions.series.filter(
            (item) => item.name == "Disgusted"
          );
          this.optionsSecond.series = seriestemp || [];
          break;

        default:
          this.optionsSecond.series = this.tempemotions.series || [];
          break;
      }
    },
  },
  async mounted() {
    try {
      this.timezoneId = this.userInfo.ID_ZONA_HORARIA;
      this.visibilityLoader = true;
      this.idcampania = this.$route.query.campaign;
      await this.setgraphSentiment(this.idcampania, "campaign");
      this.consultAllSemantics();
    } catch (error) {
      console.error("[Error] into cycle mounted function", error);
    } finally {
      this.visibilityLoader = false;
    }
  },
};
</script>
<style>
.msg-off-semantic {
  color: white !important;
}
.semantic {
  padding-bottom: 50px;
}
.container-semantic {
  margin-top: 55px;
  margin-bottom: 17px;
}
.selected-emotion {
  width: 172px;
}
.panel-selects {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}
.panel-1,
.panel-2 {
  display: flex;
}
.text-label-semantic {
  align-self: center;
}
#mynetwork {
  width: 100%;
  height: 600px;
  border: 1px solid lightgray;
  margin: auto;
}

.vis-network canvas {
  background: rgb(22, 41, 56) none repeat scroll 0% 0%;
}

.display {
  display: none;
}

.temp {
  width: 1112px;
  height: 600px;

  margin: auto;
}
.select-actions {
  width: 173px;
  height: 35px;
  line-height: 20px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgb(72 72 72 / 18%);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  padding-left: 15px;
  color: #5d6b88;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-segun-actions {
  width: 173px;
  height: 35px;
  line-height: 20px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgb(72 72 72 / 18%);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  padding-left: 15px;
  color: #5d6b88;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-emotion {
  width: 173px;
  height: 35px;
  line-height: 20px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgb(72 72 72 / 18%);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  padding-left: 15px;
  color: #5d6b88;
  font-size: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40'  d='M2 0L0 1zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.select-format {
  font-family: Oxygen;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  font-size: 12px;
  width: 8rem;
}
.semantic-text {
  font-size: 1.25em;
  font-weight: 600;
  color: #2c3852;
  text-align: left;
}
.semantic-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 50px;
}
.emotion-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
</style>
