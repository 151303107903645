<template>
  <div class="w-100 bg-mainbg campaign-general-overview-wrap">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <bread-crumb />
    <div class="detail-content-box">
      <div class="row mt-4 mb-4">
        <div class="col-7 pl-0">
          <div class="d-flex align-items-center">
            <b-img
              v-bind:src="this.imagen"
              class="rounded-circle h-12 w-12 mr-2"
            ></b-img>
            <span class="font-weight-bold text-lg mr-2 text-secondary"
              >{{ this.nombreMarca }}
            </span>
            <div v-for="(item, index) in consultas" :key="item + index">
              <i
                v-if="item.idRedSocial == 1"
                @click="abrirpestania(item.dscUrlRedsocial)"
                class="mr-2 fab fa-facebook text-facebook"
              ></i>
              <i
                v-if="item.idRedSocial == 4"
                @click="abrirpestania(item.dscUrlRedsocial)"
                class="mr-2 fab fa-youtube text-youtube"
              ></i>
              <i
                v-if="item.idRedSocial == 3"
                @click="abrirpestania(item.dscUrlRedsocial)"
                class="mr-2 fab fa-instagram text-instagram"
              ></i>
              <i
                v-if="item.idRedSocial == 2"
                @click="abrirpestania(item.dscUrlRedsocial)"
                class="mr-2 fab fa-twitter text-twitter"
              ></i>
              <i
                v-if="item.idRedSocial == 5"
                @click="abrirpestania(item.dscUrlRedsocial)"
                class="mr-2 fab fa-linkedin text-linkedin"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-5 pr-0">
          <div class="main-select">
            <div class="brand-select">
              <multi-select-custom
                class="selected-brand"
                :selected="selectedDefaultBrand"
                :options="getListBrands(marcas)"
                @selectValue="selectedBrand"
              />
            </div>
            <div class="coin-select">
              <multi-select-custom
                class="selected-coin"
                :selected="selectedDefaultCoin"
                :options="getListCoins(monedas)"
                @selectValue="selectedCoin"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <ScoreCards :obj="totalResumen"></ScoreCards>
      </div>
      <div>
        <ComparativeCards
          :mayor="this.campMayores.nom_campania"
          :menor="this.campMenor.nom_campania"
          :totalcomments="totalcomments"
          :totalengage="totalengagement"
          :totalnewaudience="totoalnewaudience"
        ></ComparativeCards>
        <OverviewGraphs ref="overviewgraphs"> </OverviewGraphs>
      </div>
    </div>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import ScoreCards from "@/components/general-overview/ScoreCards.vue";
import ComparativeCards from "@/components/general-overview/ComparativeCards.vue";
import OverviewGraphs from "@/components/general-overview/OverviewGraphs.vue";
import {
  loaderCustom,
  MultiSelectCustom,
  BreadCrumb,
  AlertSetting,
  IscModalAlerts,
  UserObjMixin,
  mapActions,
  mapGetters,
} from "@isc/styleguide";

export default {
  name: "GeneralOverView",
  components: {
    BreadCrumb,
    ScoreCards,
    ComparativeCards,
    OverviewGraphs,
    loaderCustom,
    MultiSelectCustom,
    IscModalAlerts,
  },
  data() {
    return {
      marcas: [],
      monedas: [],
      consultas: [],
      totalResumen: {},
      totalDetalleResumen: {},
      campMayor: [],
      campMenor: [],
      nombreUsuario: "",
      idCondRedSocial: "",
      nombreMarca: "",
      imagen: "",
      campanias: "",
      nros: 0,
      total_interacciones: 0,
      impresiones: 0,
      countCampanias: 0,
      interactionPorcs: 0,
      conversiones: 0,
      campMayores: {},
      campMenor: {},
      visibilityLoader: false,
      totoalnewaudience: 0,
      totalcomments: 0,
      totalengagement: 0,
      codeBrand: 0,
      optionsBrands: [],
      selectedDefaultBrand: {
        value: 0,
        text: "No Account",
      },
      selectedDefaultCoin: {
        value: 1,
        text: "PEN",
      },
    };
  },
  mixins: [AlertSetting, UserObjMixin],
  computed: {
    ...mapGetters({
      getBrands: "campaign/campaign/getBrands",
    }),
  },
  async mounted() {
    try {
      this.visibilityLoader = true;
      await this.setCurrentObjUser(); // function from styleguide (Mixin)
      await this.getBrandsList({
        clientId: this.userInfo.id_cliente,
      });
      this.marcas = this.getBrands;
      this.monedas = await this.getCoins();
      //* Select process
      if (this.marcas.length > 0) {
        this.selectedDefaultBrand = {
          value: this.marcas[0].idMarca,
          text: this.marcas[0].dscMarca,
        };
      }
      if (this.monedas.length > 0) {
        this.selectedDefaultCoin = {
          value: this.monedas[1].id_moneda,
          text: this.monedas[1].cod_moneda,
        };
      }
      await this.consultaE();
      this.visibilityLoader = false;
    } catch (error) {
      console.log("[Error] MOUNTED: ", error);
    } finally {
      this.visibilityLoader = false;
    }
  },
  methods: {
    ...mapActions({
      getBrandsList: "campaign/campaign/GET_LIST_BRAND",
      getCoins: "campaign/campaign/GET_LIST_COINS",
      getSummarizedCampaigns: "campaign/campaign/GET_SUMMARIZED_TOTAL_CAMPAIGNS",
      getResumeTotal: "campaign/campaign/GET_RESUME_TOTAL",
    }),
    async consultaE() {
      this.visibilityLoader = true;
      let brandValue = this.selectedDefaultBrand.value;
      let selectedMoneda = this.selectedDefaultCoin.value;
      if (brandValue === 0) return;
      this.nombreMarca = this.selectedDefaultBrand.text;

      try {
        //Todo: Query social network and brand RRSS
        this.consultas = await this.getSummarizedCampaigns(brandValue);
        this.imagen =
          this.consultas[0]?.dscLogo ??
          require("../assets/all-images/off-account_150x150.png");
        //Todo: Query information campaigns */
        this.totalResumen = await this.getResumeTotal({
          idBrand: brandValue,
          idCoin: selectedMoneda,
        });

        this.totoalnewaudience = Number(this.totalResumen.t_totalnewaudience);
        this.totalcomments = Number(this.totalResumen.t_totalcomments);
        this.totalengagement = Number(this.totalResumen.t_totalengagemente);
        this.detalleResumen(this.totalResumen.detailCampaigns);
        //** sending data to general overview report*/
        this.$refs.overviewgraphs.dataTable(this.totalResumen.table);
        this.$refs.overviewgraphs.interacciones(
          this.totalResumen.detailCampaigns
        );
        this.$refs.overviewgraphs.impressions(
          this.totalResumen.detailCampaigns
        );
        this.$refs.overviewgraphs.Conversions(
          this.totalResumen.detailCampaigns
        );
        this.$refs.overviewgraphs.Rate("Audiencie");
        this.visibilityLoader = false;
      } catch (error) {
        console.error("[Error] in function consultaE: ", error);
        throw error;
      }
    },
    async detalleResumen(obj) {
      if (obj.length > 0) {
        let tempmayor = obj.reduce(function (prev, curr) {
          return prev.interactions > curr.interactions ? prev : curr;
        });
        let tempmenor = obj.reduce(function (prev, curr) {
          return prev.interactions < curr.interactions ? prev : curr;
        });
        this.campMayores = tempmayor;
        this.campMenor = tempmenor;
      }
    },
    setnameMarca(name) {
      this.nombreMarca = name;
    },
    abrirpestania(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    async selectedBrand(brand) {
      this.selectedDefaultBrand = brand;
      await this.consultaE();
    },
    async selectedCoin(coin) {
      this.selectedDefaultCoin = coin;
      await this.consultaE();
    },
    getListBrands(marcas) {
      return marcas.map((marca) => ({
        value: marca.idMarca,
        text: marca.dscMarca,
      }));
    },
    getListCoins(coins) {
      return coins.map((coin) => ({
        value: coin.id_moneda,
        text: coin.cod_moneda,
      }));
    },
  },
};
</script>
<style scoped>
.campaign-general-overview-wrap .select-style.custom-select {
  background-position-x: 90% !important;
  background-position-y: 42% !important;
}
.selected-brand {
  width: 226px;
}
.selected-coin {
  /*  width: 128px; */
}
.main-select {
  display: flex;
  justify-content: flex-end;
}
.brand-select {
  margin-right: 15px;
}
.coin-select {
  margin-right: 1px;
}
</style>
