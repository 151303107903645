<template>
  <div class="audi">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="">
      <div class="bw" style="margin-top: 40px">
        <div class="col-md-12">
          <div class="bw">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="">
                <span
                  class="font-weight-bold text-capitalize text-lsm text-secondary mr-1"
                >
                  Audience
                </span>
                <span class="text-secondary">
                  <i
                    id="impact1"
                    class="fa fa-info-circle text-sm text-skygray"
                  ></i>
                  <CpTooltipWhiteCustom
                    target="impact1"
                    title="Tooltip title"
                    message="Initial and current amount of fans, followers and subscribers per social network"
                  ></CpTooltipWhiteCustom>
                </span>
              </div>
              <div class="d-flex align-items-center">
                <label for="sel1" class="text-xs text-color-text mr-2 mb-0">
                  Social Network:
                </label>
                <multi-select-custom
                  class="selected-social-network"
                  :selected="selected"
                  :options="optionsSocialNetwork"
                  @selectValue="Filtrar"
                />
              </div>
            </div>
            <div
              :class="[
                'social_network',
                index == accounts.length - 1 ? 'last' : '',
              ]"
              v-for="(item, index) in accounts"
              :key="index"
            >
              <div class="col-md-9 col-xs-9 pos_rel p-0">
                <div class="row">
                  <!-- <button
                    class="btn_tgl"
                    role="button"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    v-if="item.idredsocial == 3 || item.idredsocial == 1"
                  >
                    <i class="fas fa-plus"></i>
                  </button> -->
                  <div class="box_social_network col-md-6 col-xs-6 p-0">
                    <div class="in_box_social_network">
                      <div class="mb-2 mt-2">
                        <span class="font-weight-bold text-lsm text-secondary"
                          >Start</span
                        >
                      </div>
                      <div class="d-flex align-items-end">
                        <div class="d-flex align-items-center">
                          <i
                            class="text-2xl pr-1"
                            :class="[
                              'fab',
                              item.idredsocial == 1
                                ? 'fa-facebook fbco'
                                : item.idredsocial == 2
                                ? 'fa-twitter twco'
                                : item.idredsocial == 3
                                ? 'fa-instagram inco'
                                : item.idredsocial == 4
                                ? 'fa-youtube ytco'
                                : item.idredsocial == 5
                                ? 'fa-linkedin lico'
                                : '',
                            ]"
                          ></i>
                          <span
                            class="text-3-5xl text-secondary font-weight-bold"
                          >
                            {{ item.audiencestart }}
                          </span>
                        </div>
                        <div class="pl-2 pb-2">
                          <span class="text-xxs text-color-text"
                            >{{
                              item.idredsocial == 1
                                ? "Fans"
                                : item.idredsocial == 2
                                ? "Followers"
                                : item.idredsocial == 3
                                ? "Followers"
                                : item.idredsocial == 4
                                ? "Subs"
                                : item.idredsocial == 5
                                ? "Followers"
                                : ""
                            }}
                          </span>
                          <span class="text-xxs text-color-text">/ </span>
                          <span class="text-xxs text-color-text">{{
                            "Since: " +  transformDate(item.startdate, userInfo.TIME_ZONE , 'DD/MM/YYYY')
                          }}</span>
                        </div>
                      </div>
                      <div class="text-secondary text-xxs">
                        {{ item.accountname }}
                      </div>
                      <div class="graf_site graf_site_fb collapse"></div>
                    </div>
                  </div>
                  <div class="box_social_network col-md-4 col-xs-6 p-0">
                    <div class="in_box_social_network">
                      <div class="mb-2 mt-2">
                        <span class="font-weight-bold text-lsm text-secondary"
                          >Current</span
                        >
                      </div>
                      <div class="d-flex align-items-end">
                        <div class="d-flex align-items-center">
                          <i
                            class="text-2xl pr-1"
                            :class="[
                              'fab',
                              item.idredsocial == 1
                                ? 'fa-facebook fbco'
                                : item.idredsocial == 2
                                ? 'fa-twitter twco'
                                : item.idredsocial == 3
                                ? 'fa-instagram inco'
                                : item.idredsocial == 4
                                ? 'fa-youtube ytco'
                                : item.idredsocial == 5
                                ? 'fa-linkedin lico'
                                : '',
                            ]"
                          ></i>
                          <span
                            class="text-3-5xl text-secondary font-weight-bold"
                          >
                            {{ item.audiencecurrent }}
                          </span>
                        </div>
                        <div class="pl-2 pb-2">
                          <span class="text-xxs text-color-text"
                            >{{
                              item.idredsocial == 1
                                ? "Fans"
                                : item.idredsocial == 2
                                ? "Followers"
                                : item.idredsocial == 3
                                ? "Followers"
                                : item.idredsocial == 4
                                ? "Subs"
                                : item.idredsocial == 5
                                ? "Followers"
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="text-secondary text-xxs">
                        {{ item.accountname }}
                      </div>
                      <div class="graf_site graf_site_fb collapse"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-xs-3 pr-0">
                <div class="row d-flex justify-content-end">
                  <div
                    :class="[
                      'bx_nt_if',
                      item.idredsocial == 1
                        ? 'fbbg'
                        : item.idredsocial == 2
                        ? 'twbg'
                        : item.idredsocial == 3
                        ? 'inbg'
                        : item.idredsocial == 4
                        ? 'ytbg'
                        : item.idredsocial == 5
                        ? 'libg'
                        : '',
                    ]"
                  >
                    <div class="">
                      <span class="text-white font-weight-bold text-lsm">
                        Goal
                      </span>
                    </div>
                    <div class="d-flex align-items-end">
                      <div class="d-flex align-items-center">
                        <i
                          class="text-2xl pr-1 text-white"
                          :class="[
                            'fab',
                            item.idredsocial == 1
                              ? 'fa-facebook'
                              : item.idredsocial == 2
                              ? 'fa-twitter'
                              : item.idredsocial == 3
                              ? 'fa-instagram'
                              : item.idredsocial == 4
                              ? 'fa-youtube'
                              : item.idredsocial == 5
                              ? 'fa-linkedin'
                              : '',
                          ]"
                        ></i>
                        <span
                          class="text-3-5xl text-white font-weight-bold mr-1"
                        >
                          {{ item.audiencetarget }}
                        </span>
                      </div>
                      <div class="text-xxs text-white pb-2">
                        <span>
                          {{
                            "New " +
                            (item.idredsocial == 1
                              ? "Fans"
                              : item.idredsocial == 2
                              ? "Followers"
                              : item.idredsocial == 3
                              ? "Followers"
                              : item.idredsocial == 4
                              ? "Subs"
                              : item.idredsocial == 5
                              ? "Followers"
                              : "")
                          }}
                        </span>
                        <span>/</span>
                        <span>{{ item.goal }}</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center text-white">
                      <div>
                        <span class="text-xxs pr-1">Efficiency: </span>
                        <span class="text-lsm font-weight-bold">
                          {{ item.eficency + " %" }}
                        </span>
                      </div>
                      <div>
                        <span class="text-left text-xxs d-none">
                          Target: (0 new fans)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="void"></div>
    <analysisInterest></analysisInterest>
  </div>
</template>
<script>
import analysisInterest from "../tabs/analysisInterest/analysisInterest.vue";
import { Helpers, mapGetters } from "@isc/styleguide";

import {
  loaderCustom,
  CpTooltipWhiteCustom,
  MultiSelectCustom,
} from "@isc/styleguide";

export default {
  components: {
    analysisInterest,
    loaderCustom,
    CpTooltipWhiteCustom,
    MultiSelectCustom,
  },
  data() {
    return {
      redes: [],
      accounts: [],
      objetivo: [],
      temporalAccounts: [],
      id: "",
      //select: 0,
      visibilityLoader: false,
      selected: {
        value: 0,
        text: "All",
        disabled: false,
      },
      listSocialNetworks: [
        {
          value: 1,
          text: "Facebook",
          disabled: false,
        },
        {
          value: 2,
          text: "Twitter",
          disabled: false,
        },
        {
          value: 3,
          text: "Instagram",
          disabled: false,
        },
        {
          value: 4,
          text: "Youtube",
          disabled: false,
        },
        {
          value: 5,
          text: "LinkedIn",
          disabled: false,
        },
      ],
      optionsSocialNetwork: [],
      transformDate: (date, countryTz,  format) => Helpers.transformStringDate(date, countryTz, format)
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
      getConversions: "campaign/campaign/getConversions",
    }),
  },
  props: { idCampaniasx: String },
  async mounted() {
    this.visibilityLoader = true;
    this.accounts = this.listAccounts();
    // create an array of list string including All
    this.getNewListSocialNetworks();
    this.visibilityLoader = false;
  },
  /* created() {
    this.idCampaign = this.$route.query.campaign;
  }, */
  methods: {
    listAccounts() {
      let temporal = this.getConversions;
      // getting values
      this.redes = temporal && temporal.rss ? temporal.rss : [];
      this.objetivo = temporal && temporal.objetivo ? temporal.objetivo : [];
      this.temporalAccounts =
        temporal && temporal.accounts ? temporal.accounts : [];

      let localAccounts = [];
      this.temporalAccounts.forEach((item) => {
        let findObj = this.objetivo.find(
          (it) => it.codigoRedSocial == item.idredsocial
        );
        let goal = 0;
        let eficency = "0.00";
        if (findObj) {
          goal = findObj.numerobrojetivo;
          eficency = ((item.audiencetarget / goal) * 100).toFixed(2);
        }
        item.goal = goal;
        item.eficency = eficency;
        localAccounts.push(item);
      });
      // setting localAccounts
      this.temporalAccounts = localAccounts;
      
      return localAccounts;
    },
    Filtrar(objSocialNetwork) {
      if ( objSocialNetwork.value!= 0) {
        this.accounts = this.temporalAccounts.filter(
          (it) => it.idredsocial == objSocialNetwork.value 
        );
      } else {
        this.accounts = this.temporalAccounts;
      }
    },
    calcular(n1, n2) {
      total = (n1 / n2) * 100;
      return total;
    },
    getNewListSocialNetworks() {
      this.optionsSocialNetwork = this.redes.map((red) =>
        this.listSocialNetworks.find(
          (socialNetwork) => socialNetwork.value === red
        )
      );
      this.optionsSocialNetwork.unshift({
        value: 0,
        text: "All",
        disabled: false,
      });
    },
  },
};
</script>
<style>
* {
  box-sizing: border-box;
}
.selected-social-network {
  width: 173px
}
.audi {
  font-family: "Oxygen", sans-serif;
  font-size: 12px;
}
.tlt-ico {
  color: #a2acc4;
  font-size: 14px;
}
.pd-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.bw {
  background: white !important;
}
.clear {
  clear: both;
}
.row [class^="col-xs-"],
.row [class^="col-sm-"],
.row [class^="col-md-"],
.row [class^="col-lg-"] {
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.social_network {
  padding: 10px 0;
  border-top: 1px solid #f3f4f7;
}
.iscsub {
  font-size: 1.25em;
  font-weight: 600;
  color: #2c3852;
  text-align: left;
}
.select_bx {
  position: relative;
}
.select_bx label {
  font-size: 12px;
  font-weight: normal;
  color: #5d6b88;
  margin-right: 15px;
}
.select_bx select {
  width: 173px;
  height: 35px;
  line-height: 20px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgb(72 72 72 / 18%);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  padding-left: 15px;
  color: #5d6b88;
  font-size: 12px;
}
.caret2 {
  font: 16px "Consolas", monospace;
  color: #616b86;
  right: 12px;
  top: 16px;
  padding: 10px 0px 10px 0px;
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  opacity: 0.6;
}
.select_bx label {
  font-size: 12px;
  font-weight: normal;
  color: #5d6b88;
  margin-right: 15px;
}
.tooltip_st {
  display: inline-block;
  position: relative;
  top: 1px;
}
.tooltip_st span {
  visibility: hidden;
  width: 300px;
  background-color: #00a5ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-weight: normal;
  font-family: "Oxygen";
  top: 25px;
  left: 1px;
}
.pos_rel {
  position: relative;
  top: 20px;
}
.btn_tgl {
  position: absolute;
  right: 0;
  top: 15px;
  z-index: 1;
  width: 36px;
  height: 35px;
  line-height: 32px;
  border-radius: 3px;
  box-shadow: 0px 2px 3.9px 0.1px rgb(0 0 0 / 15%);
  border: solid 1px #c7cfe2;
  background-color: #f3f4f7;
  font-size: 15px;
  color: #5d6b88;
  margin-right: 130px;
}
[role="button"] {
  cursor: pointer;
}
.pos_rel + .col-md-3 {
  padding-bottom: 0 !important;
}
.bx_nt_if {
  padding: 20px;
  padding-top: 15px !important;
  border-radius: 6px;
  width: 95%;

  float: right;
}
.fbbg {
  background: #1778f2 !important;
}
.audtar {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  color: white;
  margin-bottom: 2px;
}
.audval {
  font-size: 30px;
  text-align: left;
  color: white;
}
.audval i.fab {
  color: #fff;
}
.audval span {
  font-size: 10px;
}
.row [class^="col-xs-"],
.row [class^="col-sm-"],
.row [class^="col-md-"],
.row [class^="col-lg-"] {
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
}
.audti {
  font-size: 30px;
  text-align: left;
  margin-top: 0px;
}
.col-md-6 {
  width: 50%;
}
.ytbg {
  background: #ff6564 !important;
}
.twbg {
  background: #1da1f2 !important;
}
.inbg {
  background: #ff69a1 !important;
}
.fbco {
  color: #1778f2;
}
.ytco {
  color: #ff6564;
}
.twco {
  color: #1da1f2;
}
.inco {
  color: #ff69a1;
}
.lico {
  color: #0077b5;
}
.libg {
  background: #0077b5 !important;
}
.last {
  padding-bottom: 10px 0;
  border-bottom: 1px solid #f3f4f7;
}
.audti span {
  font-size: 10px;
}
.audti span {
  font-size: 10px;
  color: #5d6b88;
}
.void {
  width: 100%;
  padding: 20px;
}
.eficency {
  color: white;
  font-size: 15px;
}
.multiselect__option--highlight {
  background: #eef8ff !important;
  color: #000000 !important;
}
.box_social_network {
  margin-top: -20px;
}
</style>
