<template>
  <div class="nameData-content my-5">
    <div class="row text-sm px-5">
      <div class="col" style="margin-left: 40px; margin-right: 70px">
        <strong class="text-primary d-inline-block mb-4 text-lg"
          >Name your Campaign</strong
        >
        <b-form-group
          id="input-group-account"
          label="Account"
          label-for="input-account"
        >
          <b-form-select
            id="input-account"
            v-model="campaign.account_id"
            :options="accounts"
            @change="selectedAccount"
            size="sm"
            value-field="id"
            text-field="title"
          ></b-form-select>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.account_name.required"
          class="message-alert"
        >
          Account is required
        </div>
        <b-form-group id="input-group-name" label="Name" label-for="input-name">
          <b-form-input
            id="input-name"
            v-model="campaign.name"
            placeholder="Enter name"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.name.required"
          class="message-alert"
        >
          Name is required
        </div>
      </div>
      <div class="col" style="margin-left: 40px; margin-right: 70px">
        <strong class="text-primary d-inline-block mb-4 text-lg">
          Date Range
        </strong>
        <b-form-group
          id="input-group-startDate"
          label="Start Date"
          label-for="input-startDate"
        >
          <b-form-datepicker
            id="input-startDate"
            v-model="campaign.dates.start"
            class="mb-2"
            size="sm"
          ></b-form-datepicker>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.dates.start.required"
          class="message-alert"
        >
          Start Date is required
        </div>
        <b-form-group
          id="input-group-endDate"
          label="End Date"
          label-for="input-endDate"
        >
          <b-form-datepicker
            id="input-endDate"
            v-model="campaign.dates.end"
            class="mb-2"
            size="sm"
          ></b-form-datepicker>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.dates.end.required"
          class="message-alert"
        >
          End Date is required
        </div>
        <div v-if="!isValidateDate" class="message-alert">Invalid Date</div>
      </div>
    </div>
    <div class="buttons-content mt-5">
      <b-button
        type="button"
        variant="mainbg"
        class="px-4 mx-4 shadow-sm"
        @click="closeModal()"
        >Prev</b-button
      >
      <b-button
        type="button"
        variant="primary"
        class="px-4 mx-4 shadow-sm"
        @click="nextBudget()"
        >Next</b-button
      >
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "@isc/styleguide";
import moment from "moment";
export default {
  name: "NameData",
  props: ["campaign"],
  data() {
    return {
      pressButton: false,
      accounts: [],
      isValidateDate: true,
    };
  },
  created() {
    this.getAccounts();
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
      getBrands: "campaign/campaign/getBrands",
    }),
  },
  methods: {
    checkDate(start, end) {
      let mStart = moment(start);
      let mEnd = moment(end);
      return mStart.isBefore(mEnd);
    },
    nextBudget() {
      this.pressButton = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.checkDate(this.campaign.dates.start, this.campaign.dates.end)) {
        this.isValidateDate = false;
        return false;
      }
      this.$emit("next", true);
    },
    closeModal() {
      this.$emit("closeModal", true);
    },
    selectedAccount(e) {
      const accountArray = this.accounts.find((option) => option.id === e);
      this.campaign.account_name = accountArray.title;
    },
    async getAccounts() {
      try {
        const clientId = this.userInfo.id_cliente;
        const idTimeZone = this.userInfo.ID_ZONA_HORARIA;
        const _accounts = this.getBrands;

        this.campaign.timezoneId = idTimeZone;
        this.campaign.clientId = clientId;

        if (_accounts.length > 0) {
          for (var i = 0; i < _accounts.length; i++) {
            this.accounts.push({
              id: _accounts[i].idMarca,
              image: "",
              title: _accounts[i].dscMarca,
            });
          }
        }

        this.accounts.push({
          id: null,
          image: "",
          title: "Select Account",
          disabled: true,
        });
      } catch (error) {
        this.$bvToast.toast("Error loading User accounts", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  validations: {
    campaign: {
      account_name: { required },
      name: { required },
      dates: {
        start: { required },
        end: { required },
      },
    },
  },
};
</script>
<style scoped>
.nameData-content {
  text-align: center;
}
.message-alert {
  color: red;
  font-size: 12px;
}
</style>
