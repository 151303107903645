<template>
  <div id="div-goals">
    <div class="row px-5" style="padding-left: 1rem !important">
      <div class="col-4">
        <b-form-group
          id="input-group-goal-type"
          label="Goal"
          label-for="input-goal-type"
        >
          <b-form-select
            size="sm"
            id="input-goal-type"
            v-model="newGoalType"
            :options="options"
            @change="selectMetric($event)"
            value-field="idMetrica"
            text-field="nombreMetrica"
          ></b-form-select>
        </b-form-group>
        <span
          v-if="!newGoalType && emptyGoals && campaign.goals.length === 0"
          class="span-alert"
          >Type Goal is required</span
        >
      </div>
      <div class="col-4" style="margin-top: 32px">
        <b-form-group id="input-group-leads" label-for="input-name-lead">
          <b-form-input
            size="sm"
            id="input-name-lead"
            type="number"
            placeholder="0.0"
            min="1"
            v-model="newGoalNumber"
          ></b-form-input>
        </b-form-group>
        <span
          v-if="!newGoalNumber && campaign.goals.length === 0 && emptyGoals"
          class="span-alert"
          >Amount of Lead is required</span
        >
        <span
          v-if="newGoalNumber < 0 && campaign.goals.length === 0"
          class="span-alert"
          >Amount of Lead must be positive</span
        >
        <!-- <span
          v-if="newGoalNumber % 1 != 0 && campaign.goals.length === 0"
          class="span-alert"
          >Amount of Lead must be integer</span
        > -->
      </div>
      <div class="col-4" style="margin-top: 32px">
        <b-button variant="primary" @click="addGoal()" size="sm" class="mb-4">
          <i class="fa fa-plus" style="font-size: 14px"></i> Add New</b-button
        >
      </div>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr class="text-xs" style="display: none">
            <th>Type of goal</th>
            <th>Number of goal</th>
            <th style="display: none">id goal</th>
          </tr>
        </thead>
        <tbody id="containerlistgoals"></tbody>
      </table>
      <div v-if="emptyGoals && campaign.goals.length === 0" class="span-alert">
        You should need almost 1 goal
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "@isc/styleguide";
export default {
  name: "Leads",
  props: ["campaign"],
  data() {
    return {
      options: [],
      newGoalType: null,
      nameMetric: "",
      newGoalNumber: null,
      emptyGoals: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      const tableHtml = document.getElementById("containerlistgoals");
      tableHtml.innerHTML = this.campaign.table;
    });
  },
  computed: {
    ...mapGetters({
      metricsLead: "campaign/campaign/getListMetricsLead",
    }),
  },
  created() {
    this.optionsSorted();
  },
  methods: {
    async optionsSorted() {
      this.options = [
        { nombreMetrica: "Select a metric", idMetrica: null },
        ...this.metricsLead,
      ];
      return this.options.sort();
    },
    selectMetric(idMetrica) {
      const metricaArray = this.options.find(
        (option) => option.idMetrica === idMetrica
      );
      this.nameMetric = metricaArray.nombreMetrica;
    },
    addGoal() {
      // validation goal number
      this.numberGoalValidation();
      if (this.newGoalType && this.newGoalNumber > 0) {
        this.emptyGoals = false;
        const containerGoals = document.querySelector("#containerlistgoals");
        const row_goal = document.createElement("tr");

        const cell_goal_type = document.createElement("td");
        cell_goal_type.textContent = `${this.newGoalType}`;
        cell_goal_type.style = "display:none;";

        const cell_goal_name = document.createElement("td");
        cell_goal_name.textContent = `${this.nameMetric}`;
        cell_goal_name.style = "width: 50%";
        const cell_goal_number = document.createElement("td");
        cell_goal_number.innerHTML =
          "<input type='text' class='form-control form-control-sm' value='" +
          `${this.newGoalNumber}` +
          "'>";
        row_goal.appendChild(cell_goal_name);
        row_goal.appendChild(cell_goal_number);
        row_goal.appendChild(cell_goal_type);

        if (this.campaign.goals.length > 0) {
          const metricaArray = this.campaign.goals.find(
            (option) => option.newGoalType === this.newGoalType
          );
          if (metricaArray != undefined) {
            if (metricaArray.newGoalType > 0) {
              this.$bvToast.toast("Goal already exist.", {
                variant: "danger",
                solid: true,
                title: "Message",
              });

              return false;
            }
          }
        }

        containerGoals.appendChild(row_goal);

        this.campaign.goals.push({
          newGoalType: this.newGoalType,
          nameMetric: this.nameMetric,
          newGoalNumber: this.newGoalNumber,
        });

        this.options = this.options.filter((option) => {
          return option != this.newGoalType;
        });

        const tableHtml = document.getElementById("containerlistgoals");
        this.campaign.table = tableHtml.innerHTML;
        this.newGoalType = null;
        this.newGoalNumber = null;
      } else {
        this.emptyGoals = true;
        return;
      }
    },
    numberGoalValidation() {
      if (!this.newGoalNumber) return;
      if (this.newGoalNumber < 0) return;
      //if (this.newGoalNumber % 1 != 0) return;
    },
  },
};
</script>
<style scoped>
.span-alert {
  color: red;
  font-size: 12px;
  /* font-weight: bold; */
}

#div-goals {
  position: relative;
  border: 1px solid #cdd1da;
  padding: 8px;
  border-radius: 4px;
  /* width: 510px; */
  color: #9ca6b0;
  margin: 10px;
  margin-top: 22px;
}
</style>
